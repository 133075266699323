const Config = {
	// BACKEND_URL: 'http://localhost:9000/',
	// CONTRACT_URL: 'http://localhost:3006/',
	// BACKEND_URL: 'https://s0nwvs979h.execute-api.us-west-2.amazonaws.com/test/',

	//test configuration
	// AUTHORIZENET_URL: 'https://apitest.authorize.net/xml/v1/request.api/',
	// CONTRACT_URL: 'https://contracts-test-real-testsite.web.app/',
	// BACKEND_URL: 'https://1e5ru2rj20.execute-api.us-west-2.amazonaws.com/test2/',
	// ENV: 'test',
	// BUSINESS: 'test',
	// NAME: 'Test Rentals',

	//production stuff
	CONTRACT_URL: 'https://contracts.golux.app/',
	AUTHORIZENET_URL: 'https://api.authorize.net/xml/v1/request.api/',

	// demo account
	// BACKEND_URL: 'https://glqmgqi5h4.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'demo',
	// BUSINESS: 'demo',
	// NAME: 'GoLux Rentals',

	//drive LA things
	// BACKEND_URL: 'https://z90ld6ouq9.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'drivela',
	// BUSINESS: 'drivela',
	// NAME: 'Drive LA',

	//quality rentalz
	BACKEND_URL: 'https://gzlalegiqj.execute-api.us-west-2.amazonaws.com/prod/',
	ENV: 'quality',
	NAME: 'Quality Rentalz',
	BUSINESS: 'quality',

	// // // AMD AUTO
	// BACKEND_URL: 'https://pk14l34rk8.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'amd',
	// BUSINESS: 'amd',
	// NAME: 'AMD AUTO GROUP',

	// Bozz auto group
	// BACKEND_URL: 'https://u66zqpfqn3.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'bozz-prod',
	// BUSINESS: 'bozz',
	// NAME: 'BOZZ AUTO GROUP',

	// RPM Auto Group
	// BACKEND_URL: 'https://6tpq6z7z4k.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'rpm-prod',
	// BUSINESS: 'rpm-auto',
	// NAME: 'RPM AUTO GROUP',

	//dde things
	// BACKEND_URL: 'https://nbz1rdwbo6.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'prod',
	// BUSINESS: 'dde',
	// NAME: 'DDE Rentals',

	//drive LA 2nd location
	// BACKEND_URL: 'https://l466fg76ik.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'drivela2',
	// BUSINESS: 'drivela2',
	// NAME: 'Drive LA',

	// Unknwn client easysaves@gmail.com
	// BACKEND_URL: 'https://12vlll0yud.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'unk-easy',
	// BUSINESS: 'unk-easy',
	// NAME: 'UNKNOWN RENTALS',

	// Unknwn client mario
	// BACKEND_URL: 'https://32uk567mth.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'unk-mario',
	// BUSINESS: 'unk-mario',
	// NAME: 'UNKNOWN RENTALS',

	// unknown client gham
	// BACKEND_URL: 'https://5zjz3hjqkf.execute-api.us-west-2.amazonaws.com/prod/',
	// ENV: 'gham',
	// BUSINESS: 'gham',
	// NAME: 'Unknown Rentals',


	AWS_CLOUDFRONT_URL: 'https://d3nk8x6ofxzl5v.cloudfront.net/',
	GOOGLE_API_KEY: "AIzaSyD_2BuNLQ-dqt1mEdrzFuRAgtwEZ3G9okM",
	WHITELIST_UIDS: [
		'eVg887hoVGXUvbvINqXR7gI6YMH2', //admin@gmail.com
		'dtFyaZpli2RmdRL2s6MEwFkatAm2', //support from old firebase project
		'nulN1N8kpwNcwWgUecahhPwykC73', //support@gmail.com
		'iUDUuOEqQUhBFxdfMqoIfEU30sR2', //info@dderentlas new
		'OfZUU493wmaP3oV9J0uHm9qf98G2',//info@drive-la.com
		'EKmi7ULrDkaF7N2lTdSRijGZ3Jr1', //info@fantasyexoticrentals.com
		'PlxpoPdunJhkj8M6FA4kLcl01oD3', //info@peacock-rentals.com
		'DUtbTHE9EGVZiDXIt6sOosbbsD63', //quality rentalz concierge
		'FvK0FyCNibeU4lNDTBKhw0JA8yv1', //amdautogroupinc@gmail.com AMT AUTO GROUP
		'YQInS3A6eRUt5QQWItGkvu7KLmG2', //eazysaves@gmail.com Unknown client
		'M9yLyEA1ONamTek3U0w5trrHh623', //mariopedrik1@gmail.com	unknown client
		'SMnzAgNi84cCcOn89lLIUrYxEJ33', //bozzautogroup@gmail.com
		'u0RkNPc15qdaHHwIlseNw0UoNjt2' //rpmautogroupcorp@gmail.com
	],
	AWS_REGION: 'us-west-2',

	AWS_ACCESS_KEY_ID: "AKIAW4FID5CQEU7KQ47H",
	AWS_SECRET_ACCESS_KEY: "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",
	FCM_KEY: "BNJxF--5eocbJGxiGV3W9h123FnajQr_wGjqnsexx-wD7nGdiFv33yU7Q6n1Mw8gAVG1MsS0WZZhW9UW5c9Zoxs",
	RANDOM: 'SmvpTKEyH6j0', //for admin project
}

export default Config
